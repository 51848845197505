import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About";
import CarListing from "../pages/CarListing";
import CarDetails from "../pages/CarDetails";
import Blog from "../pages/Blog";
import NotFound from "../pages/NotFound";
import ContactForm from "../pages/ContactForm";
import Fleet from "../pages/Fleet";

const Routers = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="/home" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/cars" element={<CarListing />} />
      <Route path="/cars/:slug" element={<CarDetails />} />
      <Route path="/blogs" element={<Blog />} />
      <Route path="/fleet:slug" element={<Fleet />} />
      <Route path="/contactForm " element={<ContactForm />} />
      <Route path="/contactForm/:slug" element={<ContactForm />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Routers;
