import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import CarItem from "../components/UI/CarItem";
import carData from "../assets/data/carData";

const CarListing = () => {
  const [sortBy, setSortBy] = useState("");

  const handleSortChange = (event) => {
    setSortBy(event.target.value);
  };

  const getSortedCars = () => {
    if (sortBy === "low") {
      return [...carData].sort((a, b) => a.pax - b.pax);
    } else if (sortBy === "high") {
      return [...carData].sort((a, b) => b.pax - a.pax);
    } else {
      return carData;
    }
  };

  const sortedCars = getSortedCars();

  return (
    <Helmet title="Cars">
      <CommonSection title="Fleet" />

      <section>
        <Container>
          <Row>
            <Col lg="12">
              <div className=" d-flex align-items-center gap-3 mb-5">
                <span className=" d-flex align-items-center gap-2">
                  <i className="ri-sort-asc"></i> Sort By
                </span>

                <select value={sortBy} onChange={handleSortChange}>
                  <option value="">Select</option>
                  <option value="low">Passanger: Low to High</option>
                  <option value="high">Passanger: High to Low</option>
                </select>
              </div>
            </Col>

            {sortedCars.map((item) => (
              <CarItem item={item} key={item.id} />
            ))}
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default CarListing;
