import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../../styles/about-section.css";
import aboutImg from "../../assets/all-images/Gallery/new-suv.jpg";

const AboutSection = ({ aboutClass }) => {
  return (
    <section
      className="about__section"
      style={
        aboutClass === "aboutPage"
          ? { marginTop: "0px" }
          : { marginTop: "280px" }
      }
    >
      <Container>
        <Row>
          <Col lg="6" md="6">
            <div className="about__section-content">
              <h4 className="section__subtitle">About Us</h4>
              <h2 className="section__title">
                {" "}
                Welcome to Gators Transportation service{" "}
              </h2>
              <p className="section__description">
                Welcome to our car service website, where we are driven by a
                passion for providing exceptional transportation experiences. At
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  Gators Transportation
                </span>{" "}
                , we believe that every journey should be a seamless blend of
                comfort, reliability, and personalized service. With a
                commitment to excellence and a fleet of meticulously maintained
                vehicles, we go above and beyond to exceed our customers'
                expectations. Whether you need airport transfers, corporate
                travel solutions, or special event transportation, our team of
                professional chauffeurs is dedicated to ensuring your complete
                satisfaction. From the moment you step into one of our luxurious
                vehicles, you can trust that your ride will be smooth, safe, and
                tailored to your unique needs. Sit back, relax, and let us
                navigate the roads while you enjoy a hassle-free and
                unforgettable travel experience. Experience the difference of
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  Gators Transportation
                </span>{" "}
                , where your comfort and satisfaction are our top priorities.
              </p>
            </div>
          </Col>

          <Col lg="6" md="6">
            <div className="about__img">
              <img src={aboutImg} alt="" className="w-100" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutSection;
