import React from "react";

import CommonSection from "../components/UI/CommonSection";
import Helmet from "../components/Helmet/Helmet";
import AboutSection from "../components/UI/AboutSection";
import { Container, Row, Col } from "reactstrap";

import driveImg from "../assets/all-images/Gallery/disney-suv-4.jpg";
import "../styles/about.css";

const About = () => {
  return (
    <Helmet title="About">
      <CommonSection title="About Us" />
      <AboutSection aboutClass="aboutPage" />
      <section className="about__page-section">
        <Container>
          <Row>
            <Col lg="6" md="6" sm="12">
              <div className="about__page-img">
                <img src={driveImg} alt="" className="w-100 rounded-3" />
              </div>
            </Col>

            <Col lg="6" md="6" sm="12">
              <div className="about__page-content">
                <h2 className="section__title">
                  We Are Committed To Provide Safe Ride Solutions
                </h2>

                <p className="section__description">
                  At our core, safety is our unwavering commitment. We strive to
                  provide secure ride solutions, ensuring peace of mind for all.
                  With each journey, our passengers' well-being remains
                  paramount. From meticulously vetted drivers to
                  state-of-the-art vehicle maintenance, we leave no stone
                  unturned. Our dedication extends beyond technology; it's
                  ingrained in our DNA. Our platform prioritizes safety
                  features, including real-time tracking, emergency assistance,
                  and rigorous safety protocols. We invest in continuous
                  training, empowering our drivers to handle any situation with
                  expertise and care. No matter the time, day or night, we're
                  your trusted guardians on the road. Count on us for safe
                  rides, always.
                </p>

                <div className=" d-flex align-items-center gap-3 mt-4">
                  <span className="fs-4">
                    <i class="ri-phone-line"></i>
                  </span>

                  <div>
                    <h6 className="section__subtitle">
                      We are here to answer any concers
                    </h6>
                    <h4>000 000 0000</h4>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default About;
