import React, { useState } from "react";



const ContactForm = () => {
  return (
    <div>
      <iframe
        title="JotForm"
        src="https://form.jotform.com/jsform/223587894986179"
        width="100%"
        height="500px"
        frameborder="0"
        scrolling="no"
      ></iframe>
    </div>
  );
};

export default ContactForm;
