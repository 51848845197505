import React from "react";

import Slider from "react-slick";
import { Container } from "reactstrap";
import { Link } from "react-router-dom";

import "../../styles/hero-slider.css";

const HeroSlider = () => {
  const settings = {
    fade: true,
    speed: 10000,
    autoplaySpeed: 12000,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
  };
  return (
    <Slider {...settings} className="hero__slider">
      <div className="slider__item slider__item-01 mt0">
        <Container>
          <div className="slider__content ">
            <h1 className="text-light mb-3">Ride in Style</h1>
            <h2 className="text-light mb-4">Premium Taxi Services Fleet</h2>
            <h4 className="text-light mb-3">
              Our premium taxi services fleet offers a luxurious and comfortable
              transportation experience for all occasions. 
            </h4>
          </div>
        </Container>
      </div>
      <div className="slider__item slider__item-02 mt0">
        <Container>
          <div className="slider__content ">
            <h1 className="text-light mb-3">
              Cruise into Comfort: Experience Seamless Car Services
            </h1>
            <h4 className="text-light mb-4">
              From cruises to parks to airports, our services are designed to
              make your vacation more enjoyable and stress-free.
            </h4>
          </div>
        </Container>
      </div>

      <div className="slider__item slider__item-03 mt0">
        <Container>
          <div className="slider__content ">
            <h1 className="text-light mb-3">
              Cruise into Comfort: Experience Seamless Car Services
            </h1>
            <h4 className="text-light mb-4">
              From cruises to parks to airports, our services are designed to
              make your vacation more enjoyable and stress-free.
            </h4>
          </div>
        </Container>
      </div>

      <div className="slider__item slider__item-04 mt0">
        <Container>
          <div className="slider__content ">
            <h1 className="text-light mb-3">
              Cruise into Comfort: Experience Seamless Car Services
            </h1>
            <h4 className="text-light mb-4">
              From cruises to parks to airports, our services are designed to
              make your vacation more enjoyable and stress-free.
            </h4>
          </div>
        </Container>
      </div>
    </Slider>
  );
};

export default HeroSlider;
