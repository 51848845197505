import React, { useState } from "react";
import axios from "axios";
import { Form, FormGroup } from "reactstrap";
import "../../styles/FindCarForm.css";

const FindCarForm = () => {
  const [formData, setFormData] = useState({
    fromAddress: "",
    toAddress: "",
    journeyDate: "",
    journeyTime: "",
    carType: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post("/api/send-email", formData); // Replace '/api/send-email' with your server-side endpoint URL
      alert("Email sent successfully!");
      setFormData({
        fromAddress: "",
        toAddress: "",
        journeyDate: "",
        journeyTime: "",
        carType: "",
      });
    } catch (error) {
      console.error(error);
      alert("Failed to send email. Please try again later.");
    }
  };

  return (
    <Form className="form" onSubmit={handleSubmit}>
      <div className="d-flex align-items-center justify-content-between flex-wrap">
        <FormGroup className="form__group">
          <input
            type="text"
            name="fromAddress"
            placeholder="From address"
            value={formData.fromAddress}
            onChange={handleChange}
            required
          />
        </FormGroup>

        <FormGroup className="form__group">
          <input
            type="text"
            name="toAddress"
            placeholder="To address"
            value={formData.toAddress}
            onChange={handleChange}
            required
          />
        </FormGroup>

        <FormGroup className="form__group">
          <input
            type="date"
            name="journeyDate"
            placeholder="Journey date"
            value={formData.journeyDate}
            onChange={handleChange}
            required
          />
        </FormGroup>

        <FormGroup className="form__group">
          <input
            className="journey__time"
            type="time"
            name="journeyTime"
            placeholder="Journey time"
            value={formData.journeyTime}
            onChange={handleChange}
            required
          />
        </FormGroup>

        <FormGroup className="select__group">
          <select
            name="carType"
            value={formData.carType}
            onChange={handleChange}
            required
          >
            <option value="">Select Car Type</option>
            <option value="suv">SUV</option>
            <option value="sedan">Sedan</option>
            <option value="sprinter">Sprinter</option>
            <option value="stretch">Stretch</option>
          </select>
        </FormGroup>

        <FormGroup className="form__group">
          <button type="submit" className="btn find__car-btn">
            Submit
          </button>
        </FormGroup>
      </div>
    </Form>
  );
};

export default FindCarForm;
