// import all images from assets/images directory
import img01 from "../all-images/Gallery/new-suv.jpg";
import img02 from "../all-images/Gallery/suv1.jpg";
import img03 from "../all-images/Gallery/van.jpg";
import img04 from "../all-images/Gallery/suv15.jpg";

const carData = [
  {
    id: 1,
    brand: "Sedan",
    carName: "SUV",
    imgUrl: img01,
    llug: "3 large",
    slug: "1 small",
    pax: 2,
    seatType: "Heated seats",
    description: " Type your text here.",
    price: 100,
  },

  {
    id: 2,
    brand: "SUV",
    carName: "SUV",
    imgUrl: img01,
    llug: "3 large",
    slug: "2 small",
    pax: 5,
    seatType: "Heated seats",
    description: " Type your text here.",
    price: 10,
  },
  {
    id: 3,
    brand: "Stretch",
    carName: "SUV",
    imgUrl: img01,
    llug: "3",
    slug: "2",
    pax: 10,
    seatType: "Heated seats",
    description: " Type your text here.",
    price: 12,
  },
  {
    id: 4,
    brand: "Sprinter",
    carName: "SUV",
    imgUrl: img01,
    llug: "3 large",
    slug: "2 small",
    pax: 12,
    seatType: "Heated seats",
    description: " Type your text here.",
    price: 1,
  },
];

export default carData;
